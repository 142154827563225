import React from "react";
import Iframe from "react-iframe";
import "./style-contact.scss";
import { Container, Row, Col } from "react-bootstrap";
import "../ranbow.scss";
import { useTranslation } from "react-i18next";


export default function Contact() {
  
    const { t } = useTranslation();
  return (
          <div id="Contact">
           
      
    </div>
  
    );
}

